import React, {useEffect} from "react"
import Header from "../components/header"
import {Helmet} from "react-helmet"
import teamsData from "../../static/data/teams.json"
import { Chart } from 'chart.js'

function Page({teams}) {
  useEffect(() => {
    const teams = teamsData;
    const seasons = ['2018','2019','2020','2021','2022','2023','2024'];

    let teamNames = [];
    let teamColors = [];
    let teamAvgScores = [];
    let teamAboveAvgPercs = [];
    let teamWRs = [];
    let teamTrades = [];

    for (var i = 0; i < teams.length; i++) {
      teamNames.push(teams[i].name);
      teamColors.push(teams[i].color);

      let totalPF = 0;
      let totalPA = 0;
      let totalAvgScore = 0;
      let avgScores = [];
      let totalAboveAvgPerc = 0;
      let aboveAvgPercs = [];
      let totalWins = 0;
      let totalLosses = 0;
      let totalTies = 0;
      let places = [];
      let totalWRs = 0;
      let totalTrades = 0;

      // loop over seasons and add up totals
      for (let key in teams[i].seasons) {
        const season = teams[i].seasons[key];

        if(teams[i].seasons.hasOwnProperty(key)){
          totalPF += season.pointsFor;
          totalPA += season.pointsAgainst;
          totalAvgScore += season.avgScore;
          avgScores.push(season.avgScore);
          totalAboveAvgPerc += season.aboveAvgPerc;
          aboveAvgPercs.push(season.aboveAvgPerc);
          totalWins += season.wins;
          totalLosses += season.losses;
          totalTies += season.ties;
          places.push({'season':key,'place':season.place});
          totalWRs += season.weeklyRewards;
          totalTrades += season.tradeCount;
        }
      }    
          
      teamAvgScores.push((totalAvgScore/avgScores.length).toFixed(2));
      teamAboveAvgPercs.push((totalAboveAvgPerc/aboveAvgPercs.length).toFixed(2));
      teamWRs.push(totalWRs.toFixed(2));
      teamTrades.push(totalTrades.toFixed(2));

      // add data to global teams obj
      teams[i].totalWins = totalWins;
      teams[i].totalLosses = totalLosses;
      teams[i].totalTies = totalTies;
      teams[i].totalPF = totalPF.toFixed(2);
      teams[i].totalPA = totalPA.toFixed(2);
      teams[i].places = places;
      teams[i].totalWRs = totalWRs;
      teams[i].totalTrades = totalTrades;
      teams[i].totalAvgScore = (totalAvgScore/avgScores.length).toFixed(2);
      teams[i].totalAboveAvgPerc = (totalAboveAvgPerc/aboveAvgPercs.length).toFixed(2);
    }

    // HELPER FUNCTIONS
      const default_cmp = function(a, b) {
        if (a === b) return 0;
        return a < b ? -1 : 1;
      },
      getCmpFunc = function(primer) {
        var cmp = default_cmp;
        if (primer) {
          cmp = function(a, b) {
            return default_cmp(primer(a), primer(b));
          };
        }

        return function(a, b) {
          return -1 * cmp(a, b);
        };
      };

      function sortBy() {
        var fields = [],
            n_fields = arguments.length,
            field, name, cmp;

        // preprocess sorting options
        for (var i = 0; i < n_fields; i++) {
          field = arguments[i];
          if (typeof field === 'string') {
            name = field;
            cmp = default_cmp;
          }
          else {
            name = field.name;
            cmp = getCmpFunc(field.primer);
          }
          fields.push({
            name: name,
            cmp: cmp
          });
        }

        return function(A, B) {
          var name, cmp, result;
          for (var i = 0, l = n_fields; i < l; i++) {
            result = 0;
            field = fields[i];
            name = field.name;
            cmp = field.cmp;

            result = cmp(A[name], B[name]);
            if (result !== 0) break;
          }
          return result;
        }
      }

      function getSuffix(number) {
        const j = number % 10;
        const k = number % 100;
        if (j === 1 && k !== 11) {
          return 'st';
        }
        if (j === 2 && k !== 12) {
          return 'nd';
        }
        if (j === 3 && k !== 13) {
          return 'rd';
        }
        return 'th';
      }

      function generateExpandable(containerId, items, stat, isDescending = true) {
        const statLabels = {
          totalPF: "Total Points For",
          totalPA: "Total Points Against",
          totalAvgScore: "Team Average Score",
          totalWRs: "All Time Weekly Reward Wins",
          totalTrades: "All Time Trades (2019 and later)",
          totalAboveAvgPerc: "% of Games with Above Average Score"
        };
      
        const container = document.getElementById(containerId);
        container.innerHTML = '';
      
        const itemId = containerId + '-item';
      
        const button = createToggleButton(itemId);
        const element = createElement(statLabels[stat], itemId);
      
        const itemsSorted = [...items].sort((a, b) => {
          const diff = a[stat] - b[stat];
          return isDescending ? -diff : diff;
        });
      
        itemsSorted.forEach((item, index) => {
          const row = document.createElement('tr');
      
          const rankCell = document.createElement('td');
          rankCell.textContent = index + 1;
          row.appendChild(rankCell);
      
          const nameCell = document.createElement('td');
          nameCell.textContent = item.name;
          row.appendChild(nameCell);
      
          const statCell = document.createElement('td');
          statCell.textContent = item[stat];
          row.appendChild(statCell);
      
          element.querySelector('tbody').appendChild(row);
        });
      
        container.appendChild(button);
        container.appendChild(element);
      
        button.addEventListener('click', () => toggleElement(button, element));
      }
      
      function createToggleButton(itemId) {
        const button = document.createElement('button');
        button.className = 'expandable-trigger';
        button.setAttribute('aria-expanded', 'false');
        button.setAttribute('aria-controls', itemId);
        button.textContent = 'Show Text Version';
        return button;
      }
      
      function createElement(statLabel, itemId) {
        const element = document.createElement('table');
        element.id = itemId;
        element.classList.add('styled', 'expandable');
      
        const thead = document.createElement('thead');
        const headerRow = document.createElement('tr');
      
        const columnNames = ['Rank', 'Team', statLabel];
        columnNames.forEach(column => {
          const th = document.createElement('th');
          th.textContent = column;
          headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);
        element.appendChild(thead);
      
        const tbody = document.createElement('tbody');
        element.appendChild(tbody);
      
        return element;
      }
      
      function toggleElement(button, element) {
        const isExpanded = button.getAttribute('aria-expanded') === 'true';
        if (isExpanded) {
          element.classList.remove('expanded');
          button.setAttribute('aria-expanded', 'false');
          button.textContent = 'Show Text Version';
        } else {
          element.classList.add('expanded');
          button.setAttribute('aria-expanded', 'true');
          button.textContent = 'Hide Text Version';
        }
      }

      function generatePlacesList(teams, containerId, wrapperId) {
        const container = document.getElementById(containerId);
        container.innerHTML = '<div id="'+wrapperId+'" class="expandable"></div>';
        const wrapper = document.getElementById(wrapperId);

        const button = createToggleButton(wrapperId);
        container.prepend(button);
      
        button.addEventListener('click', () => toggleElement(button, document.getElementById(wrapperId)));

        teams.forEach(item => {
          const teamWrap = document.createElement('div');
          teamWrap.classList.add('team-wrapper');
          teamWrap.innerHTML = '<h3>'+item.name+'</h3>';
          wrapper.appendChild(teamWrap);

          const ul = document.createElement('ul');

          item.places.forEach(place => {
            const li = document.createElement('li');
            const placeWithSuffix = place.place + getSuffix(place.place);

            li.textContent = `${place.season}: ${placeWithSuffix}`;
            ul.appendChild(li);
          });

          teamWrap.appendChild(ul);
        });
      }

    const teamsStandings = teams.slice(0).sort(sortBy(
      {
        name: 'totalWins',
        primer: parseInt
      },
      {
        name: 'totalPF',
        primer: parseInt
      }
    ));

    // output standings
    let standingsMarkup = '';

    for (var y = 0; y < teamsStandings.length; y++) {
      let place = y+1;
      let placeAppend = 'th';
      let team = teamsStandings[y];
      let topPlaces = ' ';
      let places1 = [];
      let places2 = [];
      let places3 = [];

      if (place === 1) {
        placeAppend = 'st';
      }
      else if (place === 2) {
        placeAppend = 'nd';
      }

      // find season places
      for (var x = 0; x < team.places.length; x++) {
        if (team.places[x].place < 4 && team.places[x].place > 0) {
          topPlaces += '<div class="trophy place-'+team.places[x].place+'" aria-label="finished in place '+team.places[x].place+' in '+team.places[x].season+'"><span class="fas fa-trophy"></span><span class="desc">'+team.places[x].season+'</span></div>';
        }

        if (team.places[x].place === 1) {
          places1.push(team.places[x].season);
        }
        if (team.places[x].place === 2) {
          places2.push(team.places[x].season);
        }
        if (team.places[x].place === 3) {
          places3.push(team.places[x].season);
        }
      }

      standingsMarkup += '<li><span class="label">'+place+'<sup>'+placeAppend+'</sup></span>'+team.name+' ('+team.totalWins+'-'+team.totalLosses+(team.totalTies?'-'+team.totalTies:'')+')'+topPlaces+'</li>';
    }

    document.getElementById('at-standings').innerHTML = standingsMarkup;

    const teamsSortedPF = teams.slice(0).sort(sortBy(
      {
        name: 'totalPF',
        primer: parseInt
      }
    ));

    new Chart(document.getElementById("chartPF"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teamsSortedPF.map((team,idx) => (team.totalPF)),
          backgroundColor: teamsSortedPF.map((team,idx) => (team.color)),
        }],

        labels: teamsSortedPF.map((team,idx) => (team.name))
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
              stacked: true
          }],
          yAxes: [{
              stacked: true
          }]
        }
      }
    });
    generateExpandable('chartPF-table-container', teams, "totalPF");

    const teamsSortedPA = teams.slice(0).sort(sortBy(
      {
        name: 'totalPA',
        primer: parseInt
      }
    ));

    new Chart(document.getElementById("chartPA"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teamsSortedPA.map((team,idx) => (team.totalPA)),
          backgroundColor: teamsSortedPA.map((team,idx) => (team.color)),
        }],

        labels: teamsSortedPA.map((team,idx) => (team.name))
      },
      options: {
        legend: {
          display: false
        }
      }
    });
    generateExpandable('chartPA-table-container', teams, "totalPA");

    const teamsSortedAvgScore = teams.slice(0).sort(sortBy(
      {
        name: 'totalAvgScore',
        primer: parseInt
      }
    ));

    new Chart(document.getElementById("chartAvgScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teamsSortedAvgScore.map((team,idx) => (team.totalAvgScore)),
          backgroundColor: teamsSortedAvgScore.map((team,idx) => (team.color)),
        }],
    
        labels: teamsSortedAvgScore.map((team,idx) => (team.name))
      },
      options: {
        legend: {
          display: false
        }
      }
    });
    generateExpandable('chartAvgScore-table-container', teams, "totalAvgScore");

    new Chart(document.getElementById("chartAboveAvgPerc"), {
      type: 'polarArea',
      data: {
        datasets: [{
          data: teamAboveAvgPercs,
          backgroundColor: teamColors,
        }],
    
        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
      }
    });
    generateExpandable('chartAboveAvgPerc-table-container', teams, "totalAboveAvgPerc");

    new Chart(document.getElementById("chartPlaces"), {
      type: 'line',
      data: {
        labels: seasons.map((season,idx) => (season)),
        datasets: 
          teams.map((team,idx) => (
            {
              label: team.name,
              data: team.places.map((seasonPlace,idx2) => (seasonPlace.place)),
              fill: false,
              borderColor: team.color,
              backgroundColor: team.color,
              borderCapStyle: 'square'
            }
          ))
      },
      options: {
        legend: {
          display: true,
          position: 'top'
        },
        scales: {
          yAxes: [{
            ticks: {
              reverse: true
            }
          }]
        },
        responsive: true,
      }
    });
    generatePlacesList(teams, 'chartPlaces-text-container', 'chartPlaces-text-wrap');

    const teamsSortedWR = teams.slice(0).sort(sortBy(
      {
        name: 'totalWRs',
        primer: parseInt
      }
    ));

    new Chart(document.getElementById("chartWR"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teamsSortedWR.map((team,idx) => (team.totalWRs)),
          backgroundColor: teamsSortedWR.map((team,idx) => (team.color)),
        }],
    
        labels: teamsSortedWR.map((team,idx) => (team.name))
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
              stacked: true
          }],
          yAxes: [{
              stacked: true
          }]
        }
      }
    });
    generateExpandable('chartWR-table-container', teams, "totalWRs");
    
    const teamsSortedTrades = teams.slice(0).sort(sortBy(
      {
        name: 'totalTrades',
        primer: parseInt
      }
    ));

    new Chart(document.getElementById("chartTrades"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teamsSortedTrades.map((team,idx) => (team.totalTrades)),
          backgroundColor: teamsSortedTrades.map((team,idx) => (team.color)),
        }],
    
        labels: teamsSortedTrades.map((team,idx) => (team.name))
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
              stacked: true
          }],
          yAxes: [{
              stacked: true
          }]
        }
      }
    });
    generateExpandable('chartTrades-table-container', teams, "totalTrades");
  }, [teams]);

  return (
    <React.Fragment>
      <Helmet>
        <title>History | Chode Dynasty</title>

        <script defer src="https://use.fontawesome.com/releases/v5.6.3/js/all.js" integrity="sha384-EIHISlAOj4zgYieurP0SdoiBYfGJKkgWedPHH4jCzpCXLmzVsw1ouK59MuUtP4a1" crossorigin="anonymous"></script>
      </Helmet>

      <Header />
      <main>
        <div className="row">
          <div className="inner">
            <h1>League History</h1>
            
            <div className="cols-2">
              <div>
                <h2>All Time Standings</h2>
                <ol id="at-standings" className="standings no-pad"></ol>
              </div>

              <div>
                <h2>Champions</h2>
                <ol className="standings champs no-pad">
                  <li><span className="label">2024</span> Charm City Trading Co.</li>
                  <li><span className="label">2023</span> Mahomebois</li>
                  <li><span className="label">2022</span> Take a poo leave a poo</li>
                  <li><span className="label">2021</span> Take a poo leave a poo</li>
                  <li><span className="label">2020</span> Wet Ass Poopy</li>
                  <li><span className="label">2019</span> Jackson Jacks Off</li>
                  <li><span className="label">2018</span> Sportsball Bois</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>All Time Points For</h2>
            <canvas id="chartPF"></canvas>
            <div id="chartPF-table-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>All Time Points Against</h2>
            <canvas id="chartPA"></canvas>
            <div id="chartPA-table-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Team Average Score</h2>
            <canvas id="chartAvgScore"></canvas>
            <div id="chartAvgScore-table-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Games with Above Average Score</h2>
            <canvas id="chartAboveAvgPerc"></canvas>
            <div id="chartAboveAvgPerc-table-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Season Finishes</h2>
            <canvas id="chartPlaces"></canvas>
            <div id="chartPlaces-text-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>All Time Weekly Reward Wins</h2>
            <canvas id="chartWR"></canvas>
            <div id="chartWR-table-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>All Time Trades (2019 and later)</h2>
            <canvas id="chartTrades"></canvas>
            <div id="chartTrades-table-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Record Book</h2>
            <p>The following records include data only from the regular season and playoff games. Data from the consolation bracket is not included. For the Most Points records, only players that were in starting rosters are eligible.</p>
            <p>* = only includes data from 2019 onwards</p>
            <table className="styled record-book">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Record</th>
                  <th>Holder</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Most Points (Game)</td>
                  <td>192.60</td>
                  <td>Piss Jugs (2018 - Week 10)</td>
                </tr>
                <tr>
                  <td>Most Points (Season)</td>
                  <td>2150</td>
                  <td>Take a poo leave a poo (2022)</td>
                </tr>
                <tr>
                  <td>Most Points - Playoffs (Game)*</td>
                  <td>178.94</td>
                  <td>Take a poo leave a poo (2021 - Round 1)</td>
                </tr>
                <tr>
                  <td>Most Points (Matchup)*</td>
                  <td>339.92</td>
                  <td>Tutu Hot 4U (171.40) vs Take a poo leave a poo (168.52), 2021 - Week 2</td>
                </tr>
                <tr>
                  <td>Fewest Points (G)*</td>
                  <td>35.94</td>
                  <td>frankakatank (2019 - Week 7)</td>
                </tr>
                <tr>
                  <td>Fewest Points (S)</td>
                  <td>1081.43</td>
                  <td>frankakatank (2019)</td>
                </tr>
                <tr>
                  <td>Fewest Points (M)*</td>
                  <td>118.94</td>
                  <td>Dinguses (70.62) vs frankakatank (48.32), 2019 - Week 2</td>
                </tr>
                <tr>
                  <td>Closest Score*</td>
                  <td>0.12 difference</td>
                  <td>
                    Ckbenz vs Lil' Jergz (2019 - Week 3) - 115.90 - 116.02
                    <br /><br />
                    Stroud's Chodes vs Charm City Trading Co. (2024 - Week 13) - 97.28 - 97.16
                  </td>
                </tr>
                <tr>
                  <td>Most Points Against (S)</td>
                  <td>1835.7</td>
                  <td>Team Frank (2018)</td>
                </tr>
                <tr>
                  <td>Fewest Points Against (S)</td>
                  <td>1330.86</td>
                  <td>Human Poop (2024)</td>
                </tr>
                <tr>
                  <td>Longest Win Streak</td>
                  <td>21</td>
                  <td>Take a poo leave a poo (2021 - 2022)</td>
                </tr>
                <tr>
                  <td>Longest Loss Streak</td>
                  <td>13</td>
                  <td>frankakatank (2018)</td>
                </tr>
                <tr>
                  <td>Most Playoff Appearances</td>
                  <td>6</td>
                  <td>DamnBryant</td>
                </tr>
                <tr>
                  <td>Most 69 Point Games*</td>
                  <td>1</td>
                  <td>Pissjugs</td>
                </tr>
                <tr>
                  <td>Most Points - QB (G)*</td>
                  <td>57.88</td>
                  <td>J. Allen (2024 - Week 14)</td>
                </tr>
                <tr>
                  <td>Most Points - QB (S)</td>
                  <td>495.70</td>
                  <td>P. Mahomes (2018)</td>
                </tr>
                <tr>
                  <td>Most Points - RB (G)</td>
                  <td>54.70</td>
                  <td>A. Kamara (2020 - Week 16)</td>
                </tr>
                <tr>
                  <td>Most Points - RB (S)</td>
                  <td>415.20</td>
                  <td>C. McCaffrey (2019)</td>
                </tr>
                <tr>
                  <td>Most Points - WR (G)</td>
                  <td>51.40</td>
                  <td>T. Hill (2020 - Week 12)</td>
                </tr>
                <tr>
                  <td>Most Points - WR (S)</td>
                  <td>368.50</td>
                  <td>C. Kupp (2021)</td>
                </tr>
                <tr>
                  <td>Most Points - TE (G)</td>
                  <td>38.50</td>
                  <td>D. Waller (2020 - Week 13)</td>
                </tr>
                <tr>
                  <td>Most Points - TE (S)</td>
                  <td>260.30</td>
                  <td>T. Kelce (2022)</td>
                </tr>
                <tr>
                  <td>Most Points - D/ST (G)*</td>
                  <td>38</td>
                  <td>Cowboys (2023 - Week 1)</td>
                </tr>
                <tr>
                  <td>Most Points - D/ST (S)</td>
                  <td>240</td>
                  <td>Patriots (2019)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}

export default Page;