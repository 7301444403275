import React, {useEffect} from "react"
import Header from "../../components/header"
import Trade from "../../components/trade"
import { FaTrophy } from 'react-icons/fa'
import teamsData from "../../../static/data/teams.json"
import tradesData from "../../../static/data/trades.json"
import { Chart } from 'chart.js'
import {Helmet} from "react-helmet"

function Page({teams}) {
  const season = "2024";
  const teamNames = teamsData.map((team) => team.seasons[season].name);
  const teamColors = teamsData.map((team) => team.color);
  const trades = tradesData[0][season].trades;

  function generateExpandable(containerId, teams, season, stat, isDescending = true) {
    const statLabels = {
      tradeCount: "Number of Trades",
      pointsFor: "Total Points For",
      pointsPossible: "Total Points Possible",
      pointsAgainst: "Total Points Against",
      avgScore: "Average Score",
      aboveAvgPerc: "% of Games with Above Average Score",
      originalTeamPerc: "% of Original Team Remaining",
      pointsPossiblePerc: "% of Possible Points Scored"
    };
  
    const container = document.getElementById(containerId);
    container.innerHTML = '';
  
    const itemId = containerId + '-item';
  
    const button = createToggleButton(itemId);
    const element = createElement(statLabels[stat], itemId);
  
    const teamsSorted = [...teams].sort((a, b) => {
      const diff = a.seasons[season][stat] - b.seasons[season][stat];
      return isDescending ? -diff : diff;
    });
  
    teamsSorted.forEach((team, index) => {
      const row = document.createElement('tr');
  
      const rankCell = document.createElement('td');
      rankCell.textContent = index + 1;
      row.appendChild(rankCell);
  
      const nameCell = document.createElement('td');
      nameCell.textContent = team.seasons[season].name;
      row.appendChild(nameCell);
  
      const statCell = document.createElement('td');
      statCell.textContent = team.seasons[season][stat];
      row.appendChild(statCell);
  
      element.querySelector('tbody').appendChild(row);
    });
  
    container.appendChild(button);
    container.appendChild(element);
  
    button.addEventListener('click', () => toggleElement(button, element));
}

  
  function createToggleButton(itemId) {
    const button = document.createElement('button');
    button.className = 'expandable-trigger';
    button.setAttribute('aria-expanded', 'false');
    button.setAttribute('aria-controls', itemId);
    button.textContent = 'Show Text Version';
    return button;
  }
  
  function createElement(statLabel, itemId) {
    const element = document.createElement('table');
    element.id = itemId;
    element.classList.add('styled', 'expandable');
  
    const thead = document.createElement('thead');
    const headerRow = document.createElement('tr');
  
    const columnNames = ['Rank', 'Team', statLabel];
    columnNames.forEach(column => {
      const th = document.createElement('th');
      th.textContent = column;
      headerRow.appendChild(th);
    });
    thead.appendChild(headerRow);
    element.appendChild(thead);
  
    const tbody = document.createElement('tbody');
    element.appendChild(tbody);
  
    return element;
  }
  
  function toggleElement(button, element) {
    const isExpanded = button.getAttribute('aria-expanded') === 'true';
    if (isExpanded) {
      element.classList.remove('expanded');
      button.setAttribute('aria-expanded', 'false');
      button.textContent = 'Show Text Version';
    } else {
      console.log(element);
      element.classList.add('expanded');
      button.setAttribute('aria-expanded', 'true');
      button.textContent = 'Hide Text Version';
    }
  }

  function generateHighestScoresList(containerId, wrapperId) {
    const container = document.getElementById(containerId);
    container.innerHTML = '';

    const itemId = containerId + '-wrapper';
    const table = createElement("Score - Week", itemId);

    const button = createToggleButton(itemId);
    container.prepend(button);
  
    button.addEventListener('click', () => toggleElement(button, document.getElementById(wrapperId)));
    
    const highScores = [
      {
        "team": teamNames[7],
        "week": '9',
        "score": '189.5'
      },
      {
        "team": teamNames[3],
        "week": '16',
        "score": '177.28'
      },
      {
        "team": teamNames[3],
        "week": '14',
        "score": '175.96'
      },
      {
        "team": teamNames[3],
        "week": '17',
        "score": '165.68'
      },
      {
        "team": teamNames[2],
        "week": '15',
        "score": '164.38'
      },
      {
        "team": teamNames[0],
        "week": '6',
        "score": '163.2'
      },
      {
        "team": teamNames[3],
        "week": '3',
        "score": '161.72'
      },
      {
        "team": teamNames[9],
        "week": '11',
        "score": '156.02'
      },
      {
        "team": teamNames[2],
        "week": '14',
        "score": '155.68'
      },
      {
        "team": teamNames[7],
        "week": '17',
        "score": '151.48'
      }
    ];

    highScores.forEach((item, index) => {
      const row = document.createElement('tr');
  
      const rankCell = document.createElement('td');
      rankCell.textContent = index + 1;
      row.appendChild(rankCell);
  
      const nameCell = document.createElement('td');
      nameCell.textContent = item.team;
      row.appendChild(nameCell);
  
      const statCell = document.createElement('td');
      statCell.textContent = `${item.score} - Week ${item.week}`;
      row.appendChild(statCell);
  
      table.querySelector('tbody').appendChild(row);
    });
  
    container.prepend(button);
    container.appendChild(table);
  }
  
  useEffect(() => {
    const teams = teamsData;  

    new Chart(document.getElementById("chartPF"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].pointsFor),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });
    generateExpandable('chartPF-table-container', teams, season, "pointsFor");

    new Chart(document.getElementById("chartPP"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].pointsPossible),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });
    generateExpandable('chartPP-table-container', teams, season, "pointsPossible");

    new Chart(document.getElementById("chartPercPP"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].pointsPossiblePerc),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });
    generateExpandable('chartPercPP-table-container', teams, season, "pointsPossiblePerc");
    

    new Chart(document.getElementById("chartPA"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].pointsAgainst),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });
    generateExpandable('chartPA-table-container', teams, season, "pointsAgainst", false);
    
    new Chart(document.getElementById("chartAvgScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].avgScore),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });
    generateExpandable('chartAvgScore-table-container', teams, season, "avgScore");

    new Chart(document.getElementById("chartTrades"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
            data: teams.map((team) => team.seasons[season].tradeCount),
            backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
    generateExpandable('chartTrades-table-container', teams, season, "tradeCount");

    // doesn't pull from array
    new Chart(document.getElementById("chartHighestScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: [
            '189.5',
            '177.28',
            '175.96',
            '165.68',
            '164.38',
            '163.2',
            '161.72',
            '156.02',
            '155.68',
            '151.48'
          ],
          backgroundColor: [
            teamColors[7],
            teamColors[3],
            teamColors[3],
            teamColors[3],
            teamColors[2],
            teamColors[0],
            teamColors[3],
            teamColors[9],
            teamColors[2],
            teamColors[7]
          ],
        }],
        labels: [
          teamNames[7] + ' - Week 9',
          teamNames[3] + ' - Week 16',
          teamNames[3] + ' - Week 14',
          teamNames[3] + ' - Week 17',
          teamNames[2] + ' - Week 15',
          teamNames[0] + ' - Week 6',
          teamNames[3] + ' - Week 10',
          teamNames[9] + ' - Week 11',
          teamNames[2] + ' - Week 14',
          teamNames[7] + ' - Week 17'
        ]
      },
      options: {
        legend: {
          display: false
        }
      }
    });
    generateHighestScoresList('chartHighestScore-container','chartHighestScore-container-wrapper')

    new Chart(document.getElementById("chartAboveAvgPerc"), {
      type: 'polarArea',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].aboveAvgPerc),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
      }
    });
    generateExpandable('chartAboveAvgPerc-table-container', teams, season, "aboveAvgPerc");

    new Chart(document.getElementById("chartWeeklyPlaces"), {
      type: 'line',
      data: {
        labels: [
          'Week 1',
          'Week 2',
          'Week 3',
          'Week 4',
          'Week 5',
          'Week 6',
          'Week 7',
          'Week 8',
          'Week 9',
          'Week 10',
          'Week 11',
          'Week 12',
          'Week 13',
          'Week 14',
          'Week 15',
          'Week 16',
          'Week 17'
        ],
        datasets: 
          teams.map((team,idx) => (
            {
              label: team.seasons[season].name,
              data: team.seasons[season].weeklyPlaces,
              fill: false,
              borderColor: team.color,
              backgroundColor: team.color,
              borderCapStyle: 'square'
            }
          ))
      },
      options: {
        legend: {
          display: true,
          position: 'top',
        },
        scales: {
          yAxes: [{
            ticks: {
              reverse: true
            }
          }]
        },
        responsive: true,
      }
    });
    function generateWeeklyPlacesList(containerId, wrapperId, teams, season) {
      const container = document.getElementById(containerId);
      container.innerHTML = '<div id="'+wrapperId+'" class="expandable"></div>';
      const wrapper = document.getElementById(wrapperId);

      const button = createToggleButton(wrapperId);
      container.prepend(button);
    
      button.addEventListener('click', () => toggleElement(button, document.getElementById(wrapperId)));
      
      function getPlaceSuffix(place) {
        if (place % 10 === 1 && place % 100 !== 11) return `${place}st`;
        if (place % 10 === 2 && place % 100 !== 12) return `${place}nd`;
        if (place % 10 === 3 && place % 100 !== 13) return `${place}rd`;
        return `${place}th`;
      }
    
      teams.forEach(team => {
        const teamWrapper = document.createElement('div');
        teamWrapper.classList.add('team-wrapper');
    
        const teamName = document.createElement('h3');
        teamName.textContent = team.seasons[season].name;
        teamWrapper.appendChild(teamName);
    
        const ul = document.createElement('ul');
        ul.classList.add('weekly-places-list');
    
        team.seasons[season].weeklyPlaces.forEach((place, index) => {
          const li = document.createElement('li');
          li.textContent = `Week ${index + 1}: ${getPlaceSuffix(place)}`;
          ul.appendChild(li);
        });
    
        teamWrapper.appendChild(ul);
        wrapper.appendChild(teamWrapper);
      });
    }
    generateWeeklyPlacesList('chartWeeklyPlaces-container', 'chartWeeklyPlaces-wrap', teams, season);

    new Chart(document.getElementById("chartOriginalTeam"), {
      type: 'polarArea',
      data: {
        datasets: [{
            data: teams.map((team) => team.seasons[season].originalTeamPerc),
            backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
      }
    });
    generateExpandable('chartOriginalTeam-table-container', teams, season, "originalTeamPerc");

    new Chart(document.getElementById("chartTier1"),{
      type:"horizontalBar",
      data:{
        labels: teamNames,
        datasets:[
          {
            label: "QB",
            data: teams.map((team) => team.seasons[season].tier1_QB.length),
            backgroundColor: "#C62828"
          },
          {
            label: "RB",
            data: teams.map((team) => team.seasons[season].tier1_RB.length),
            backgroundColor: "#2E7D32"
          },
          {
            label: "WR",
            data: teams.map((team) => team.seasons[season].tier1_WR.length),
            backgroundColor: "#1565C0"
          },
          {
            label: "TE",
            data: teams.map((team) => team.seasons[season].tier1_TE.length),
            backgroundColor: "#F9A825"
          },
          {
            label: "D/ST",
            data: teams.map((team) => team.seasons[season].tier1_DST.length),
            backgroundColor: "#6A1B9A"
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    });
  }, [teams]) 

  return (
    <React.Fragment>
      <Helmet>
        <title>{season} Season Recap | Chode Dynasty</title>
      </Helmet>
      
      <Header />
      <main>
        <div className="row">
          <div className="inner">
            <h1>{season} Season Recap</h1>
            
            <div className="cols-2">
              <div>
                <h2>Final Standings</h2>
                <ol className="standings">
                  <li>
                    <span className="label">1<sup>st</sup></span> Charm City Trading Co. (9-5)
                    <div className="trophy place-1"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">2<sup>nd</sup></span> Take a poo leave a poo (11-3)
                    <div className="trophy place-2"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">3<sup>rd</sup></span> Chodemissioner (9-5)
                    <div className="trophy place-3"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">4<sup>th</sup></span> Human Poop (8-6)
                  </li>
                  
                  <li>
                    <span className="label">5<sup>th</sup></span> Wee Lads (8-6)
                  </li>
                  
                  <li>
                    <span className="label">6<sup>th</sup></span> Team jhoffman (7-7)
                  </li>
                  
                  <li>
                    <span className="label">7<sup>th</sup></span> D.P. LoveKraft (6-8)
                  </li>
                  
                  <li>
                    <span className="label">8<sup>th</sup></span> Stroud's Chodes (5-9)
                  </li>
                  
                  <li>
                    <span className="label">9<sup>th</sup></span> Piss Jugman (4-10)
                  </li>
                  
                  <li>
                    <span className="label">10<sup>th</sup></span> Narcoleptic Skydivers (3-11)
                  </li>
                </ol>
              </div>

              <div>
                <h2>Champion Roster</h2>
                <table className="styled champ">
                  <thead>
                    <tr>
                      <th>Position</th>
                      <th>Player</th>
                      <th>Points</th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr>
                      <td>QB</td>
                      <td>J. Burrow (CIN)</td>
                      <td>42.98</td>
                    </tr>
                    <tr>
                      <td>RB</td>
                      <td>J. Gibbs (DET)</td>
                      <td>24.30</td>
                    </tr>
                    <tr>
                      <td>RB</td>
                      <td>J. Conner (ARI)</td>
                      <td>1.80</td>
                    </tr>
                    <tr>
                      <td>WR</td>
                      <td>J. Chase (CIN)</td>
                      <td>14.70</td>
                    </tr>
                    <tr>
                      <td>WR</td>
                      <td>P. Nacua (LAR)</td>
                      <td>17.90</td>
                    </tr>
                    <tr>
                      <td>TE</td>
                      <td>M. Andrews (BAL)</td>
                      <td>13.80</td>
                    </tr>
                    <tr>
                      <td>WRT</td>
                      <td>B. Thomas Jr. (JAX)</td>
                      <td>20.40</td>
                    </tr>
                    <tr>
                      <td>WRT</td>
                      <td>J. Dobbins (LAC)</td>
                      <td>14.80</td>
                    </tr>
                    <tr>
                      <td>DST</td>
                      <td>Raiders</td>
                      <td>15.00</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td><strong>165.68</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </div>
        </div>

        <div id="awards" className="row">
          <div className="inner">
            <h2>Awards</h2>
            <div className="cols-2">
              <div>
                <h3><span role="img" aria-label="rocket">🚀</span> Highest Scorer</h3>
                <h4>Take a poo leave a poo</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="pile of poo">💩</span>Lowest Scorer</h3>
                <h4>Narcoleptic Skydivers</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="four-leaf clover">🍀</span>Best Luck</h3>
                <div className="desc">(least points against)</div>
                <h4>Human Poop</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="face with head-bandage">🤕</span>Worst Luck</h3>
                <div className="desc">(most points against)</div>
                <h4>Narcoleptic Skydivers</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="fire">🔥</span>Best Manager</h3>
                <div className="desc">(highest percentage of possible points scored)</div>
                <h4>D.P. LoveKraft</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="thinking face">🤔</span>Worst Manager</h3>
                <div className="desc">(lowest percentage of possible points scored)</div>
                <h4>Piss Jugman</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="increasing chart">📈</span>Most Improved</h3>
                <div className="desc">(biggest increase from last season in points for)</div>
                <h4>Chodemissioner (+107.88)</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="decreasing chart">📉</span>Least Improved </h3>
                <div className="desc">(biggest decrease from last season in points for)</div>
                <h4>Narcoleptic Skydivers (-480.82)</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="check mark">✔️</span>Most Consistent Scorer</h3>
                <h4>Stroud's Chodes</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="cross mark">❌</span>Least Consistent Scorer</h3>
                <h4>Charm City Trading Co.</h4>
              </div>
            </div>
          </div>
        </div>
        
        <div className="row">
          <div className="inner">
            <h2>Total Points For</h2>
            <canvas id="chartPF"></canvas> 
            <div id="chartPF-table-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points Possible</h2>
            <canvas id="chartPP"></canvas>
            <div id="chartPP-table-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Possible Points Scored</h2>
            <canvas id="chartPercPP"></canvas>
            <div id="chartPercPP-table-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points Against</h2>
            <canvas id="chartPA"></canvas>
            <div id="chartPA-table-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Team Average Score</h2>
            <canvas id="chartAvgScore"></canvas>
            <div id="chartAvgScore-table-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Games with Above Average Score</h2>
            <canvas id="chartAboveAvgPerc"></canvas>
            <div id="chartAboveAvgPerc-table-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Highest Scores</h2>
            <canvas id="chartHighestScore"></canvas>
            <div id="chartHighestScore-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Weekly Finishes</h2>
            <canvas id="chartWeeklyPlaces"></canvas>
            <div id="chartWeeklyPlaces-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Percentage of Startup Draft Team</h2>
            <div className="chart-desc">(% of original team remaining)</div>
            <canvas id="chartOriginalTeam"></canvas>
            <div id="chartOriginalTeam-table-container"></div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Top Scoring Players by Position</h2>
            
            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Quarterback</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>L. Jackson</td>
                      <td>475.4</td>
                      <td>Wee Lads</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>J. Burrow</td>
                      <td>433.8</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>J. Allen</td>
                      <td>430</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>B. Mayfield</td>
                      <td>409.2</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>J. Daniels</td>
                      <td>396.6</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>J. Goff</td>
                      <td>380.7</td>
                      <td>Piss Jugman</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>S. Darnold</td>
                      <td>362.3</td>
                      <td>Narcoleptic Skydivers</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>J. Hurts</td>
                      <td>342.1</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>P. Mahomes</td>
                      <td>332.5</td>
                      <td>Wee Lads</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>B. Nix</td>
                      <td>330.2</td>
                      <td>Stroud's Chodes</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>B. Purdy</td>
                      <td>299.9</td>
                      <td>Narcoleptic Skydivers</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>K. Murray</td>
                      <td>295.4</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Running Back</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>S. Barkley</td>
                      <td>336.8</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>D. Henry</td>
                      <td>294.8</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>J. Gibbs</td>
                      <td>292.4</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Bijan Robinson</td>
                      <td>279.9</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>J. Jacobs</td>
                      <td>260</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>K. Williams</td>
                      <td>250.1</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>D. Achane</td>
                      <td>241.8</td>
                      <td>Narcoleptic Skydivers</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>J. Cook</td>
                      <td>240.9</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>A. Kamara</td>
                      <td>230.3</td>
                      <td>Wee Lads</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>J. Conner</td>
                      <td>226.3</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>C. Brown</td>
                      <td>226</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>J. Mixon</td>
                      <td>219.2</td>
                      <td>Wee Lads</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Wide Receiver</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>J. Chase</td>
                      <td>318.9</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>J. Jefferson</td>
                      <td>258.1</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>A. St. Brown</td>
                      <td>249</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>B. Thomas</td>
                      <td>226.7</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>C. Lamb</td>
                      <td>211.9</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>T. McLaurin</td>
                      <td>209.6</td>
                      <td>D.P. LoveKraft</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>M. Nabers</td>
                      <td>203.2</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>J. Smith-Njigba</td>
                      <td>198.7</td>
                      <td>Piss Jugman</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>D. London</td>
                      <td>195.1</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>G. Wilson</td>
                      <td>192.3</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>M. Evans</td>
                      <td>190</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>J. Jeudy</td>
                      <td>186.6</td>
                      <td>Wee Lads</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Tight End</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>G. Kittle</td>
                      <td>193.9</td>
                      <td>D.P. LoveKraft</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>B. Bowers</td>
                      <td>193.7</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>T. McBride</td>
                      <td>178.3</td>
                      <td>D.P. LoveKraft</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>J. Smith</td>
                      <td>163.2</td>
                      <td>Narcoleptic Skydivers</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>M. Andrews</td>
                      <td>147.9</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>T. Kelce</td>
                      <td>144.9</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>S. LaPorta</td>
                      <td>134.8</td>
                      <td>Piss Jugman</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>T. Kraft</td>
                      <td>131.7</td>
                      <td>D.P. LoveKraft</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Z. Ertz</td>
                      <td>130.5</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>D. Njoku</td>
                      <td>116.5</td>
                      <td>Wee Lads</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>P. Freiermuth</td>
                      <td>114.3</td>
                      <td>Piss Jugman</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>H. Henry</td>
                      <td>112.4</td>
                      <td>Free Agent</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Defense / Special Teams</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Broncos</td>
                      <td>162</td>
                      <td>Human Poop</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Vikings</td>
                      <td>157</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Eagles</td>
                      <td>152</td>
                      <td>Take a poo leave a poo</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Packers</td>
                      <td>148</td>
                      <td>Stroud's Chodes</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Steelers</td>
                      <td>146</td>
                      <td>Charm City Trading Co.</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Texans</td>
                      <td>141</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Seahawks</td>
                      <td>134</td>
                      <td>Piss Jugman</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Bills</td>
                      <td>133</td>
                      <td>Stroud's Chodes</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Chiefs</td>
                      <td>123</td>
                      <td>Narcoleptic Skydivers</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Chargers</td>
                      <td>122</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Lions</td>
                      <td>110</td>
                      <td>D.P. LoveKraft</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Rams</td>
                      <td>108</td>
                      <td>Free Agent</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Tier 1 Player Management</h2>
            <div className="chart-desc">(how many tier 1 players each team had)</div>
            <canvas id="chartTier1"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Weekly Reward Winners</h2>
            <table className="styled weekly-reward">
              <thead>
                <tr>
                  <th>Week</th>
                  <th>Title</th>
                  <th>Challenge</th>
                  <th>Winner</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ready Set Go</td>
                  <td>Team with the highest score</td>
                  <td>Chodemissioner - 130.48</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Air It Out</td>
                  <td>Team with the QB with the longest pass</td>
                  <td>Piss Jugman - J. Goff (54.3 yds)</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Gotta Catch Em All</td>
                  <td>Team with the WR with the most receptions</td>
                  <td>Human Poop - R. Rice (12)</td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Runt of the Litter</td>
                  <td>Winning team with the lowest scoring starter</td>
                  <td>Human Poop - R. Rice, Wee Lads - G. Dulcich, Take a poo leave a poo - Eagles (0 pts)</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Got There</td>
                  <td>Team that beats its opponent by the smallest margin of victory</td>
                  <td>Stroud's Chodes - 10.4 pts</td>
                </tr>

                <tr>
                  <td>6</td>
                  <td>Lockdown</td>
                  <td>Team with the least amount of yards given up on defense</td>
                  <td>Wee Lads - Colts (241 yds)</td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>Blackjack</td>
                  <td>Team with a starter closest to 21 points without going over</td>
                  <td>Piss Jugman - J. Goff (20.8 pts)</td>
                </tr>

                <tr>
                  <td>8</td>
                  <td>Doing the Most</td>
                  <td>Team with the WR or TE with the highest yards after catch per reception (YAC/R)</td>
                  <td>jhoffman - K. Pitts (14.4 yds)</td>
                </tr>

                <tr>
                  <td>9</td>
                  <td>Magic Legs</td>
                  <td>Team with the RB with the most rushing yards</td>
                  <td>Take a poo leave a poo - S. Barkley (159 yds)</td>
                </tr>

                <tr>
                  <td>10</td>
                  <td>At Least You Tried</td>
                  <td>Highest scoring losing team</td>
                  <td>Stroud's Chodes - 102.88 pts</td>
                </tr>

                <tr>
                  <td>11</td>
                  <td>To Completion</td>
                  <td>Team with the QB with the highest completion percentage</td>
                  <td>Stroud's Chodes - B. Nix (84.8%)</td>
                </tr>

                <tr>
                  <td>12</td>
                  <td>End Zone</td>
                  <td>Team with the most offensive touchdowns scored</td>
                  <td>Stroud's Chodes & D.P. LoveKraft - 6 TDs</td>
                </tr>

                <tr>
                  <td>13</td>
                  <td>Efficient Zoomies</td>
                  <td>Team with the RB with the highest average rushing yards</td>
                  <td>Chodemissioner - K. Williams (6.9 YPC)</td>
                </tr>

                <tr>
                  <td>14</td>
                  <td>Consolation Prize</td>
                  <td>Team with the most points for on the season that didn't make the playoffs</td>
                  <td>jhoffman - 1597.02 pts</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div id="trades" className="row">
          <div className="inner">
            <h2>Trades</h2>

            <h3>Total Trades - {trades.length}</h3>
            <canvas id="chartTrades"></canvas>
            <div id="chartTrades-table-container"></div>
            
            <h3>Trade Details</h3>
            <div id="trades-details">
              {trades
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((data, id) => {
                  return <Trade key={id} data={data} teamNames={teamNames} />
                })
              }
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}

export default Page;